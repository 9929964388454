


















































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },
  setup(_, { root }) {
    const model = reactive({
      isEnabled: false,
      locationId: "",
      additionalInfo: "",
      englishAdditionalInfo: "",
      additionalInfoLocation: "over",
    });

    const state = reactive({
      loading: false,
      loaded: false,
      success: false,
      error: false as boolean | number,
      locations: [],
      search: "",
      valid: false,
      configExist: false,
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/location-module`)
        .then(({ data }) => {
          state.configExist = true;
          model.isEnabled = data.isEnabled;
          model.locationId = data.location?.id;
          model.additionalInfo = data.additionalInfo;
          model.englishAdditionalInfo = data.englishAdditionalInfo;
          model.additionalInfoLocation = data.additionalInfoLocation;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    const fetchLocations = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("location", {
          params: {
            search: state.search || undefined,
          },
        })
        .then(({ data: { locations } }) => {
          state.locations = locations.map((item: any) => ({
            id: item.id,
            name: item.name,
            city: item.city,
          }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.locations = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchLocations);

    watch(() => state.search, fetchLocations);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        isEnabled: model.isEnabled,
        locationId: model.locationId,
        additionalInfo: model.additionalInfo || null,
        englishAdditionalInfo: model.englishAdditionalInfo || null,
        additionalInfoLocation: model.additionalInfoLocation,
      };

      state.loading = true;

      if (state.configExist) {
        axiosInstance
          .put(`event/${root.$route.params.id}/location-module`, data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            state.success = true;
            state.error = false;

            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "primary",
              message: root.$tc("layout.misc.saveSuccess"),
            });
          })
          .catch((error) => {
            state.error = error.response.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => (state.loading = false));
      } else {
        axiosInstance
          .post(`event/${root.$route.params.id}/location-module`, data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            state.success = true;
            state.error = false;

            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "primary",
              message: root.$tc("layout.misc.saveSuccess"),
            });
          })
          .catch((error) => {
            state.error = error.response.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => (state.loading = false));
      }
    };

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    return { model, state, onSubmit, getErrorMessage, rules };
  },
});
